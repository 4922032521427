<template>
  <div class="container position-relative">
    <div class="py-5 row">
      <div class="col-md-8 mb-0">
        <div v-if="order">
          <div
            class="
              osahan-cart-item
              mb-3
              rounded
              shadow-sm
              bg-white
              overflow-hidden
            "
          >
            <div class="osahan-cart-item-profile bg-white p-3">
              <div class="d-flex flex-column">
                <h6 class="mb-3 font-weight-bold">
                  Your Delivery Address List
                </h6>

                <div v-if="addressList" class="row">
                  <div
                    class="
                      custom-control
                      col-lg-6
                      custom-radio
                      mb-3
                      position-relative
                      border-custom-radio
                    "
                    style="min-height: 150px"
                    v-for="item in addressList"
                    :key="item.id"
                  >
                    <input
                      type="radio"
                      :id="'address_' + item.id"
                      name="customRadioInline"
                      class="custom-control-input"
                      v-on:click="onSelectAddress(item)"
                      :checked="order.address==item.id"
                    />
                    <label
                      class="custom-control-label w-100 h-100"
                      :for="'address_' + item.id"
                    >
                      <div class="h-100">
                        <div
                          :class="
                            order.address == item.id
                              ? 'd-flex bg-primary align-items-center rounded-sm mb-2 p-2'
                              : 'd-flex bg-light align-items-center rounded-sm mb-2 p-2'
                          "
                        >
                          <h6
                            :class="
                              item.address_name == null
                                ? 'mb-0 text-muted'
                                : 'mb-0 '
                            "
                          >
                            {{
                              item.address_name == null
                                ? "N/A"
                                : item.address_name
                            }}
                          </h6>
                          <!-- <p class="mb-0 badge badge-success ml-auto">
                              <i class="icofont-check-circled"></i> Default
                            </p> -->
                        </div>

                        <div class="bg-white rounded w-100">
                          <p class="small p-3 text-muted m-0">
                            <i class="feather-map-pin"></i> {{ item.address1 }}
                          </p>
                        </div>
                        <div>
                          <a
                            v-on:click="deleteAddress(item.id)"
                            class="btn btn-outline-danger m-2 float-md-right"
                            ><i class="feather-trash"></i> DELETE</a
                          >
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div v-if="!addressList">
                  <h5 class="alert alert-warning text-center">
                    
                      <h3
                        
                      >
                      <i class="m-2  spinner-border slick-loading"></i>
                      <span>Loading ...</span>
                      </h3>

                    
                  </h5>
                </div>
                <a
                  class="btn btn-primary text-white"
                  v-on:click="showAdd"
                  data-toggle="modal"
                  data-target="#AddAddressModal"
                >
                  ADD NEW ADDRESS
                </a>
              </div>
            </div>
          </div>

          <div
            v-show="false"
            class="accordion mb-3 rounded shadow-sm bg-white overflow-hidden"
            id="accordionExample"
          >
            <div class="osahan-card bg-white border-bottom overflow-hidden">
              <div class="osahan-card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="d-flex p-3 align-items-center btn btn-link w-100"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <i class="feather-credit-card mr-3"></i> Credit/Debit Card
                    <i class="feather-chevron-down ml-auto"></i>
                  </button>
                </h2>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="osahan-card-body border-top p-3">
                  <h6 class="m-0">Add new card</h6>
                  <p class="small">
                    WE ACCEPT
                    <span class="osahan-card ml-2 font-weight-bold"
                      >( Master Card / Visa Card / Rupay )</span
                    >
                  </p>
                  <form>
                    <div class="form-row">
                      <div class="col-md-12 form-group">
                        <label class="form-label font-weight-bold small"
                          >Card number</label
                        >
                        <div class="input-group">
                          <input
                            placeholder="Card number"
                            type="number"
                            class="form-control"
                          />
                          <div class="input-group-append">
                            <button
                              type="button"
                              class="btn btn-outline-secondary"
                            >
                              <i class="feather-credit-card"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 form-group">
                        <label class="form-label font-weight-bold small"
                          >Valid through(MM/YY)</label
                        ><input
                          placeholder="Enter Valid through(MM/YY)"
                          type="number"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-4 form-group">
                        <label class="form-label font-weight-bold small"
                          >CVV</label
                        ><input
                          placeholder="Enter CVV Number"
                          type="number"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-12 form-group">
                        <label class="form-label font-weight-bold small"
                          >Name on card</label
                        ><input
                          placeholder="Enter Card number"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-12 form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="custom-checkbox1"
                            class="custom-control-input"
                          /><label
                            title=""
                            type="checkbox"
                            for="custom-checkbox1"
                            class="custom-control-label small pt-1"
                            >Securely save this card for a faster checkout next
                            time.</label
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="osahan-card bg-white border-bottom overflow-hidden">
              <div class="osahan-card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="d-flex p-3 align-items-center btn btn-link w-100"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i class="feather-globe mr-3"></i> Net Banking
                    <i class="feather-chevron-down ml-auto"></i>
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="osahan-card-body border-top p-3">
                  <form>
                    <div
                      class="btn-group btn-group-toggle w-100"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-outline-secondary active">
                        <input
                          type="radio"
                          name="options"
                          id="option1"
                          checked=""
                        />
                        HDFC
                      </label>
                      <label class="btn btn-outline-secondary">
                        <input type="radio" name="options" id="option2" /> ICICI
                      </label>
                      <label class="btn btn-outline-secondary">
                        <input type="radio" name="options" id="option3" /> AXIS
                      </label>
                    </div>
                    <hr />
                    <div class="form-row">
                      <div class="col-md-12 form-group mb-0">
                        <label class="form-label small font-weight-bold"
                          >Select Bank</label
                        ><br />
                        <select class="custom-select form-control">
                          <option>Bank</option>
                          <option>KOTAK</option>
                          <option>SBI</option>
                          <option>UCO</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="osahan-card bg-white overflow-hidden">
              <div class="osahan-card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="d-flex p-3 align-items-center btn btn-link w-100"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <i class="feather-dollar-sign mr-3"></i> Cash on Delivery
                    <i class="feather-chevron-down ml-auto"></i>
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body border-top">
                  <h6 class="mb-3 mt-0 mb-3 font-weight-bold">Cash</h6>
                  <p class="m-0">
                    Please keep exact change handy to help us serve you better
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- checkout check details -->
      <checkout-details
        v-if="order && branch"
        :order="order"
        :actionBtnMethod="'orderNow'"
        :branch="branch"
        class="mb-3 pt-sm-0"
      />
    </div>
    <!-- modals -->
    <add-address-modal
      v-on:addressAdded="getAddress()"
      :addModalShow="addModalShow"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import AddAddressModal from "../components/addAddressModal.vue";
import checkoutDetails from "../components/checkoutDetails.vue";

export default {
  components: { checkoutDetails, AddAddressModal },
  data() {
    return {
      order: null,
      branch: null,
      user: window.localStorage.getItem("curUser"),

      addressList: null,
      addModalShow: false,
    };
  },
  computed: {
    ...mapState(["baseURL", "selCity", "curUser",'curOrder']),
  },
  methods: {
    onSelectAddress(address) {

      this.order.address = address.id;

      this.getRestMenu(address.area.city, address.area.id);
    },
    updateCheckoutCheck(menu) {
      let curOrder = window.localStorage.getItem("curOrder");

      if (curOrder && curOrder != "") {
        let infos = [];
        menu.sections.forEach((sec) => {
          sec.items.forEach((i) => {
            if (i.info) {
              i.info.forEach((inf) => {
                infos.push(inf);
              });
            }
          });
        });

        if (infos.length > 0) {
          let verfiyOrder = JSON.parse(curOrder);
          let order = JSON.parse(curOrder);
          order.items = [];

          verfiyOrder.items.forEach((item) => {
            let info = infos.find((i) => i.item_code == item.menu_item_id);
            //console.log(info.id)

            if (!info) {
              alert(
                "Item (" + item.name_en + ") was not found and will be removed"
              );
            } else {
              let exTotal = item.extras
                ? item.extras.reduce((total, obj) => {
                    return total + parseFloat(obj.price_en);
                  }, 0)
                : 0;
              let optTotal = item.options
                ? item.options.reduce((total, obj) => {
                    return total + parseFloat(obj.price_en);
                  }, 0)
                : 0;
              order.items.push({
                id: order.items.length + 1,
                name_en: item.name_en,
                size_en: item.size_en,
                count: item.count,
                price: info.price.price,
                total:
                  Math.round(exTotal * 100) / 100 +
                  Math.round(optTotal * 100) / 100 +
                  info.price.price,
                menu_item_id: info.item_code,
                options: item.options,
                extras: item.extras,
                special: item.special,
              });
            }
          });
          window.localStorage.setItem("curOrder", JSON.stringify(order));
          this.order = order;
        }
      } else {
        this.order = {
          items: [],
          notes: null,
          address: null,
        };
      }
    },
    getRestMenu(selCity, selArea) {
      fetch(
        this.$store.state.baseURL +
          "/api/menu/" +
          selArea +
          "/" +
          this.$store.state.restaurant.id,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          let menu = JSON.parse(JSON.stringify(this.result)).menu;

          if (menu ) {
            if(menu.length > 0){
            menu = menu[0];
            this.$store.commit("setSelCity", selCity);
            this.$store.commit("setSelArea", selArea);
            this.updateCheckoutCheck(menu);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAddress() {
      let url =
        this.baseURL +
        "/api/profile/address?api_token=" +
        window.localStorage.getItem("jwt");

      let request = new Request(url, { method: "GET" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            this.addressList = JSON.parse(JSON.stringify(r)).data.address;
            // .filter(
            //   (f) => f.area.id == this.$store.state.selArea
            // );
          } else {
            if (r.message) console.log(r.message);
            if (r.messages) console.log(r.messages);
            if (r.message == "Invalid Token") {
              window.location = "/login";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteAddress(id) {
      let res = confirm("are you sure?");
      if (res) {
        let url =
          this.baseURL +
          `/api/profile/address/delete/${id}?api_token=${
            JSON.parse(this.curUser).token
          }`;
        let request = new Request(url, { method: "POST" });
        fetch(request)
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((r) => {
            if (r.response) {
              alert(r.data.message);
              this.getAddress();
            } else {
              if (r.message) console.log(r.message);
              else if (r.messages) console.log(r.messages);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showAdd() {
      if (this.addModalShow) this.addModalShow = false;
      else this.addModalShow = true;
    },
  },
  watch: {
   '$store.state.curOrder':{
      deep:true,
      handler(val){
       // console.log(val)
        if(val){
          let order = JSON.parse(val)
        if(order.address==null){
          this.order.address = null
        }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('getCurOrder')
    this.order = JSON.parse( this.curOrder);
    if (!this.order || this.order.items.length == 0) {
      window.location = "/home";
      //return;
    }
    this.branch = JSON.parse(window.localStorage.getItem("branch"));
    //get address
    this.getAddress();
  },
};
</script>